/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~bootstrap/scss/bootstrap";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/resposive";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYREGULAR.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYREGULAR.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYBOLD.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYBOLD.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYBOLD.OTF') format('woff2')
}

:root {
  .d-flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .h-100 {
    height: 100%;
  }

  .w-100 {
    width: 100%;
  }


  .p-horizontal-5 {
    padding: 5px 0;
  }

  .bg-primary {
    background-color: var(--ion-color-primary) !important;
    color: white;
  }

  .no-data {
    height: 100%;
    width: 100%;

    img {
      width: 320px;
      height: 320px;
    }
  }

  .table-virtual-scroll-viewport {
    position: relative;
    height: calc(100% - 200px);
    @include res_480 {
      height: calc(100% - 350px)
    }
  }

  .app-content {
    padding: 35px 50px;
    @include res_480 {
      padding: 10px 15px;
    }

    .btn-back {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px;
      background: var(--ion-button-primary);
      border-radius: 6px;
      width: 40px;
      height: 40px;
      margin-right: 35px;
    }

    .page-title {
      font-family: 'SF Pro Display', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-transform: capitalize;
      color: var(--ion-text-heading-color);
    }

    ion-content {
      --border-radius: 18px;
    }
  }

  .action-modal {
    --border-radius: 10px;
    --max-width: 758px;
    --width: 500px;
    --height: 500px;
    @include res_480 {
      --width: 300px;
      --height: 400px;
    }
  }

  .action-modal-disable {
    --border-radius: 10px;
    --max-width: 758px;
    --width: 500px;
    --height: 660px;
    overflow-y: auto;
    @include res_480 {
      --width: 90%;
    }
  }

  .app-modal {
    padding: 35px 40px;
    --border-radius: 10px;

    .modal-title {
      font-family: 'SF Pro Display', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      color: var(--ion-text-heading-color);
    }

    .close-button {
      background: #F7E4E3;
      border-radius: 66px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: 'SF Pro Text', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #C62A30;
      padding: 3px 5px;

      img {
        margin-right: 5px;
      }
    }

  }

  .app-option {
    --max-width: 150px;
    --box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .user-block {
    .profile-images {
      width: 90px;
      height: 90px;
      margin-bottom: 15px;
    }

    .upload-images {
      padding: 8px 12px 8px 8px;
      background: #71717A;
      border-radius: 50px;
      font-family: 'SF Pro Display', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #F4F4F5;
      width: 220px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }

  .block-content {
    padding: 40px 50px;
    border-radius: 16px;
    background: white;
    @include res_480 {
      padding: 10px 10px;
    }

    .button-show {
      font-family: 'SF Pro Display', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      background: #FFFFFF;
      color: #007AFF;
      display: flex;
      align-items: center;

      img {
        margin-left: 10px;
      }
    }

    .block-title {
      font-family: 'SF Pro Display', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      font-feature-settings: 'liga' off;
      color: var(--ion-text-lable-color);
    }

    .btn-add-new-panel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 13px;
      background: #FFE9C6;
      border-radius: 50px;
      font-family: 'SF Pro Display', serif;
      color: #E9642B;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;

      img {
        margin-right: 10px;
      }
    }

    .block-panel {
      .panel-name {
        font-family: 'SF Pro Display', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: white;
        padding: 15px;
        background: #23635A;
        border-radius: 8px;
        width: 100%;

        .btn-delete-panel {
          max-height: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.2);
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          border-radius: 50px;
          color: white;
          padding: 5px 8px 5px 4px;

          img {
            margin-right: 5px;
          }
        }
      }

      .panel-input-form {
        ion-item {
          .sc-ion-label-md-h {
            font-family: 'SF Pro Display', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #A5A3A9;
          }

          .radio-label {
            font-family: 'SF Pro Display', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #232D42;
          }

          .sc-ion-input-md {
            font-family: 'SF Pro Display', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            font-feature-settings: 'salt' on, 'liga' off;
            color: #27272A;
          }
        }
      }
    }
  }

  .input-form {
    margin-top: 45px;

    ion-item {
      .sc-ion-label-md-h {
        font-family: 'SF Pro Display', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #A5A3A9;
      }

      .radio-label {
        font-family: 'SF Pro Display', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #232D42;
      }

      .sc-ion-input-md {
        font-family: 'SF Pro Display', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #27272A;
      }
    }
  }

  .ng-pristine {
    font-family: "SF Pro Display", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: "salt" on, "liga" off;
    color: #27272A;
  }

  .ngb-dp-weekday {
    color: var(--ion-color-primary);
  }

  .ngb-dp-navigation-chevron{
    color: var(--ion-color-primary);
  }
  input:focus-visible {
    outline: none !important;
  }
}
