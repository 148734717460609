//responsive
$res_480: 480px;
$res_525: 525px;
$res_768: 768px;
$res_h_900: 930px;
$res_1024: 1025px;
$res_1200: 1200px;

@mixin res_480 {
  @media (max-width: #{$res_480 - 1px}) {
    @content;
  }
}

@mixin res_525 {
  @media (min-width: #{$res_525}) {
    @content;
  }
}

@mixin res_768 {
  @media (min-width: #{$res_768}) {
    @content;
  }
}

@mixin res_1024 {
  @media (min-width: #{$res_1024}) {
    @content;
  }
}

@mixin res_1200 {
  @media (min-width: #{$res_1200}) {
    @content;
  }
}

@mixin res_h_900 {
  @media (min-height: #{$res_h_900}) {
    @content;
  }
}